
// Write your custom style. Feel free to split your code to several files

.btn-secondary {
  color: #000;
  background-color: #f9aa33;
  border-color: #f9aa33;
}

.btn-outline-secondary {
  color: #f9aa33;
  background-color: transparent;
  background-image: none;
  border-color: #f9aa33;
}

.text-orange{
  color: #f9aa33;
}